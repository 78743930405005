

* {
    font-size: 12px;
}

:root {
    --primary-color: #f7941d;
    --primary-color-2: #f8a540;
    --text-color-1: #FFF;
    --primary-color-3: #5d5d5d;
    --primary-color-4: #707070;
}

::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.font-size-1 {
    font-size: 14px !important;
}

input.mandatory-field,
select.mandatory-field,
label.mandatory-field,
textarea.mandatory-field,
span.rc-time-picker.mandatory-field>input.rc-time-picker-input {
    border-left-color: var(--primary-color);
    border-left-width: 3px;
}

label.mandatory-field.custom-file-label::after {
    border-left: none;
}

.float-right {
    position: relative;
    z-index: 999;
}

form span.error,
form span.error-span {
    color: #FA0000;
}

label {
    font-weight: 600;
}

#frmLogin .spinner-border {
    color: var(--text-color-1);
}

.spinner-border {
    color: var(--primary-color);
}

#root>div>div.gig-sidebar {
    display: block;
}

/* #root > div > div.gig-body{
  margin-left: 16.666667% !important;
} */

#root>div.active>div.gig-sidebar {
    display: none;
}

#root>div.active>div.gig-body {
    margin-left: 0 !important;
    max-width: 100% !important;
    flex: 0 0 100%;
}

i.icofont-size-2 {
    font-size: 18px !important;
}

i.fas-size-1 {
    font-size: 18px !important;
}

i.fas-size-2 {
    font-size: 28px !important;
}

i.fas-size-3 {
    font-size: 18px !important;
}

a.gig-link {
    color: var(--primary-color) !important;
    text-decoration: none;
}

a.gig-link:hover {
    text-decoration: none;
}

a.gig-link-2 {
    color: inherit !important;
    text-decoration: none;
}

a.gig-link-2:hover {
    text-decoration: none;
}

.btn-primary,
.btn-secondary {
    background-color: var(--primary-color) !important;
    border-color: var(--primary-color) !important;
}

.gig-login {
    min-height: 100vh;
}

.gig-login>div {
    margin: auto;
}

.gig-login img.logo {
    height: 37px;
}

.gig-login h1 {
    font-weight: 400;
    font-size: 22px;
}

.gig-sidebar {
    color: #fff;
    background: #3c4b64;
    position: fixed !important;
    top: 0;
    bottom: 0;
    z-index: 1030;
    overflow-y: auto;
    overflow-x: hidden;
}

/* .gig-body{
    margin-left: 16.666667% !important;
} */

.gig-sidebar a.logo {
    display: block;
    padding: 20px 10px;
    border-bottom: 1px solid var(--primary-color-4);
}

.gig-sidebar a.logo>img {
    width: 100%;
}

.navigation {
    margin-top: 20px;
    width: 100%;
}

/* reset our lists to remove bullet points and padding */
.mainmenu,
.submenu {
    list-style: none;
    padding: 0;
    margin: 0;
}


/* make ALL links (main and submenu) have padding and background color */
.mainmenu a {
    display: block;
    text-decoration: none;
    padding: 10px;
    color: var(--text-color-1);
    border-bottom: 1px solid var(--primary-color-4);
    font-size: 14px;
}

.submenu a {
    border-bottom-color: #b99393 !important;
}


.mainmenu li:last-child>a {
    border-bottom: none;
}

/* add hover behaviour */
.mainmenu a:hover,
.mainmenu a.active {
    background-color: var(--primary-color-3);
    color: var(--primary-color);
    text-decoration: none;
}

.mainmenu a.hassubmenu::after {
    font-family: 'IcoFont' !important;
    content: '\ea99';
    float: right;
}

.mainmenu a.open::after {
    content: '\eaa1';
}


/* when hovering over a .mainmenu item,
  display the submenu inside it.
  we're changing the submenu's max-height from 0 to 200px;
*/

.mainmenu li.open .submenu {
    display: block !important;
    max-height: 200px;
    overflow-y: auto;
}

/*
  we now overwrite the background-color for .submenu links only.
  CSS reads down the page, so code at the bottom will overwrite the code at the top.
*/

.submenu a {
    background-color: var(--primary-color-4);
}

/* hover behaviour for links inside .submenu */
.submenu a:hover {
    background-color: #666;
}

/* this is the initial state of all submenus.
  we set it to max-height: 0, and hide the overflowed content.
*/
.submenu {
    overflow: hidden;
    max-height: 0;
    -webkit-transition: all 0.5s ease-out;
}

.gig-content {
    min-height: calc(100vh - 116px);
}

.gig-footer {
    height: 40px;
    overflow: hidden;
    font-size: 12px;
    color: #FFF;
    background-color: var(--primary-color-3);
    padding-top: 10px;
    padding-bottom: 10px;
}

.gig-header {
    height: 76px;
    border-bottom: 1px solid var(--primary-color-4);
    padding: 17px 10px;
    background-color: #F2F2F2;
}

.gig-footer img {
    height: 18px;
}

.gig-header .gig-toggle {
    padding: 13px;
}

.gig-header .dropdown {
    padding: 10px 0;
}

.gig-header .dropdown a {
    font-size: 14px;
}

.dropdown-menu {
    background-color: var(--primary-color-3) !important;
}

.dropdown-menu .dropdown-item {
    color: var(--text-color-1) !important;
    border-bottom: 1px solid var(--primary-color-4);
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #adb5bd !important;
}

.gig-header .dropdown-menu {
    right: 0;
}

.gig-header .dropdown-menu .dropdown-item {

    font-size: 14px;
    padding: 5px;

}

.dropdown-menu .dropdown-item:last-child {
    border: none;
}

.dropdown-menu .dropdown-item:hover {
    background-color: var(--primary-color) !important;
    color: white !important;
}

.gig-content h1 {
    font-weight: 400;
    font-size: 22px;
    padding: 10px 0;
}

.gig-content h2 {
    font-weight: 400;
    font-size: 18px;
    padding: 10px 0;
}

.react-bootstrap-table-pagination-list>ul {
    float: right;
}

.page-item .page-link {
    color: var(--primary-color-3) !important;
}

.page-item.active .page-link {
    background-color: var(--primary-color) !important;
    color: var(--text-color-1) !important;
    border-color: var(--primary-color) !important;
}

.gh-table table {
    table-layout: auto !important;
}

.react-bootstrap-table thead>tr>th {
    background-color: #F2F2F2;
}

.gh-table tbody>tr:hover {
    background-color: #F2F2F2;
}

.gig-content-loader,
.gig-popup-loader {
    display: none;
    text-align: center;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    padding: 5%;
    background-color: rgba(100, 100, 100, 0.3);
    z-index: 999;
}

.gig-content-loader>div {
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
}

.gig-popup-loader>div {
    margin-left: auto;
    margin-right: auto;
}

.modal-header button.close>span {
    font-size: 18px;
}

table.carrier-view {
    table-layout: fixed;
}

table.carrier-view th,
table.carrier-view td {
    word-wrap: break-word;
    width: 12.5%;
}

table.carrier-view th {
    background-color: #f7f5f5;
}

.modal-body.frmNewFilters {
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    overflow-x: hidden;
}

.rct-node-icon {
    display: none !important;
}

.rct-icons-fa4 .rct-icon-uncheck::before,
.rct-icons-fa4 .rct-icon-check::before,
.rct-icons-fa4 .rct-icon-half-check::before {
    font-size: 15px;
}

.rct-title {
    font-weight: normal !important;
}

#frmNotiMembers .user_types>.list-group-item,
#frmNotiMembers .users>.list-group-item,
#frmNotiMembers .notificationTypes>.list-group-item {
    cursor: pointer;
}

a.table-action {
    font-size: 14px;
}

.modal-body>p {
    max-height: 200px;
    word-wrap: break-word;
    overflow: auto;
}

span.form-control-timer.rc-time-picker {
    width: 100%;
}

.react-datepicker-wrapper {
    display: block !important;
}

#merge td:nth-child(2),
#merge th:nth-child(2) {
    background-color: #d4d4d4;
}

td>i.status1 {
    color: rgb(139, 139, 139);
}

td>i.status2 {
    color: rgb(26, 100, 57);
}

/*************Top Menu ************/
.drop-down02 {
    position: relative;
}

.drop-down02 .sub-menu02 {
    /* position: absolute !important; */
    left: 100%;
    top: 0;
}

/* .drop-down02 .dropdown-toggle {
  padding: 0.5rem 1.1rem !important;
} */

@media screen and (min-width: 767px) {
    .drop-down02 .sub-menu02 {
        position: static !important;
    }
}

@media screen and (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .dropdown-submenus {
        position: absolute !important;
        z-index: 99;
    }
}

@media screen and (max-width: 804px) {
    .footlogo {
        font-size: 11px;
    }

    .version p {
        font-size: 11px;
    }

    .footimg img {
        width: 110px;
        float: right;
    }
}

footer {
    width: 100%;
    background-color: #5d5d5d;
}

.head1 {
    background-color: #3c4b64;
}

.newlogo {
    width: 40px;
}

.collapse {
    background-color: #3c4b64;
}

.collapse a {
    display: inline-block;
    color: white;

    text-decoration: none;
}

.collapse a:hover {
    background-color: #777;
    border-color: #b99393;
    color: rgb(247, 148, 29);
    text-decoration-color: rgb(247, 148, 29);
}

.navbar-light .navbar-nav .nav-link {
    color: white;
}

.dropdown-submenus {
    background-color: #3c4b64;
}

.drop-down02 a:hover {
    color: rgb(247, 148, 29) !important;
}

.drop2 a:hover {
    color: rgb(247, 148, 29) !important;
}

.drop3 a:hover {
    color: rgb(247, 148, 29) !important;
}

@media screen and (max-width: 992px) {
    .login-menu .dropdown-submenus.show {}
}

.login-menu .dropdown-submenus.show {
    right: 0px;
    left: unset;
}

.no-margin {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.main {
    height: calc(100vh - 110px);
}

.login-menu .nav-item {
    display: inline-block;
}

.version {
    color: white;
    text-align: center;
}

.footlogo {
    color: white;
    padding-top: 14px;
}

#noti-count {
    width: 20px;
}

.badge-warning {
    color: black;
    background-color: rgb(247, 148, 29);
}

.float-right {
    float: right;
}

.popover-body>ul>li:first-child>span {
    font-size: 14px;
    font-weight: 1600;
}

.notify {
    width: 350px;
}

#notify {
    font-size: 20px;
    font-weight: bold;
}

ul>li:first-child {
    border: none;
}

ul>li {
    border-top: 1px solid white;
    padding: 5px;
}

ul>li>h5 {
    font-size: 14px;
    display: inline-block !important;
    color: white;
}

ul>li>p {
    color: white;
    font-size: 12px;
    margin-bottom: 0px;
}

ul>li>span {
    font-size: 9px;
    width: 20px;
    /* color: white; */
}

.text-center {
    text-align: center;
}

.drop2 {
    border: none;
}

.set-drop {
    border: none;
}

.notify li:hover {
    color: rgb(247, 148, 29) !important;
    background-color: #3c4b64 !important;
}

/*  .nav-link {
    color: #fff;
}*/

.gig-image {
    width: 200px;
    margin-left: 131px;
}

.paddingleft {
    padding-left: 30px !important;
}

.modal.show .modal-dialog.modal-90w {
    max-width: 925px;
}


.get-button {
    border: 3px solid #f7941d;
    width: 150px;
    background-color: #f7941d;
    height: 40px;

}

.get-button:hover {
    background-color: white;
}

.start-text {
    text-decoration: none;
    padding: 12px;
    font-size: 20px;
    text-align: center;
    color: white;
}

.welcome-text {
    font-size: 30px !important;
    font-weight: bolder !important;
    /* color: white; */
}

.login-text {
    font-size: 25px !important;
    font-weight: bolder !important;
    /* align-items: center; */
    /* text-align: center; */
}

.login-button {
    background-color: #ffff;
    display: inline-block;
    border-radius: 32px;
    box-shadow: 4px 7px 10px 2px rgba(0, 0, 0, 0.12);
    cursor: pointer;
}
.part2 {
    margin-bottom: 10px;
}
@media screen and (min-width: 1920px)  {
    .part2 {
        margin-bottom: 100px !important;
    }
    .login-button {
        display: inline-block;
   }
   .ht {
   height: 100vh;
   line-height: 2.2rem;
}
.carrier-style {
    padding-top: 15px;
}
.bkr2 {
    padding-top: 25px; 
}
}
@media screen and (min-width: 1680px)  {
    .part2 {
        margin-bottom: 280px;
    }
    .login-button {
        display: inline-block;
   }
   .ht {
   height: 100vh;
   line-height: 2.2rem;
}
 .carrier-style {
     padding-top: 20px;
 }
 .bkr2 {
     padding-top: 35px; 
 }
}
/* Medium devices (landscape tablets, 768px and up) */
@media screen and (min-width: 768px) and (max-width: 1024px) {
    .border-style {
        height: 100vh !important;
    }
   .part2 {
    margin-bottom: 20vh;
    line-height: 3rem;
   }
    .login-button {
        display: inline-block;
        line-height: 3rem;
    }
    .ht {
        height: 100vh;
        line-height: 2.1rem;
     }
     .bdr {
        margin-bottom: 10px;
     }
    .vhsection {
        margin-bottom:5vh;
    }
    .edtbtn {
        margin-bottom: 2vh;
    }
    .cdr {
        margin-left: -36px;
    }
    .addone {
        font-size: 11px !important;
    }
    .button-sign {
        font-size: 12px !important;
        width: -webkit-fill-available;
        border-radius: inherit;
    }
  
}
@media screen and (min-width: 600px) and (max-width: 800px) {
    .border-style {
        text-align: center;
    }
    .login-button {
        margin-bottom: 0.5vh;
    }
    .vhsection {
        margin-bottom: 10vh;
    }
    .edtbtn {
        margin-bottom: 5vh;
    }
    .cdr {
        margin-left: -36px;
    }
    .addone {
        font-size: 11px !important;
    }
}
@media screen and (min-width: 375px) and (max-width: 812px) {
    .border-style {
        text-align: center;
    }
    .login-button {
        margin-bottom: 0.5vh;
    }
    .bdr {
        margin-left: -8px;
    }
    .vhsection {
        margin-bottom: 10vh;
    }
    .addone {
        font-size: 13px !important;
    }
    .dpdwn {
        margin-left: -25px;
    }
    .edtbtn {
        margin-bottom: 5vh;
    }
    .cdr {
        margin-left: -185px;
    }
    .mbl-scn {
        display: none !important;
    }
    .button-sign {
        font-size: 12px !important;
        width: -webkit-fill-available !important;
        border-radius: inherit;
    }
}

.button-log {
    background-color: #ffff;
    border: #FFFf;
    text-align: center;
    color: #000000;
    font-size: 15px;
    font-weight: bolder;
}

.login-save {
    background-color: #555555;
    border-radius: 24px;
    cursor: pointer;
}

.login-save-disable {
    background-color: #adaaaa;
    border-radius: 24px;
}

.button-save {
    background-color: #555555;
    border: #555555;
    text-align: center;
    color: #ffff;
    font-size: 15px;
    font-weight: 700;
    line-height: 38px;
}

.button-save-disable {
    background-color: #adaaaa;
    border: #adaaaa;
    text-align: center;
    color: #ffff;
    font-size: 15px;
    font-weight: 700;
    line-height: 38px;
}

.reg-save {
    background-color: #f7941d;
    border-radius: 24px;
}


.fa-eye:before {
    margin-left: -30px;
    position: absolute;
    z-index: 1;
    cursor: pointer;
    top: 50%;
    transform: translate(-50%, -50%);
}

.sign-button {
    background-color: #E77F00;
    border-radius: 40px;
   cursor: pointer;
}

.button-sign {
    background-color: #E77F00;
    border: #E77F00;
    text-align: center;
    color: #ffff;
    font-size: 15px;
    /* font-weight: bolder; */
}

/* .wrap-input100 {
    width: 100%;
    position: relative;
    border-bottom: 2px solid #adadad;
    margin-bottom: 37px;
  }

  .input100 {
    font-family: Poppins-Regular;
    font-size: 15px;
    color: #555555;
    line-height: 1.2;
  
    display: block;
    width: 100%;
    height: 45px;
    background: transparent;
    padding: 0 5px;
  } */


.welcome-text span {
    font-size: 30px !important;
    font-weight: bolder !important;
    color: #f7941d;
}

.para-text {}

.back {
    background-color: white;
    opacity: 0.7;
    color: black;
    height: 400px;
    padding: 25px;


}

.inner-row {
    flex-direction: column;
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.back-row {
    padding-left: 0px !important;
    margin-left: 0px !important;
}


.to-word {
    color: #f7941d;
    font-weight: bolder;
}

.card-header {
    background-color: red !important;
}

#border {
    background-color: green !important;
}


.card-body>ul {
    height: 80vh;
    overflow: auto;
}


.trucklogo {
    width: 100%;
    height: 100vh;
}

.companylogo {
    width: 50%;
    height: 100%;

}

.description {
    font-size: 15px;
}

.main-row {
    width: 100vw;
}


.drps {
    transform: translate(-15px, 21px) !important;
}

.border-style {
    height: 100vh;
    /* margin-top: 10px;
    margin-bottom: 10px; */
    background-color: #EAEAEA;
}

.carrier-style {
    height: auto;
    background-color: #EAEAEA;
}

.reg-btn {
    position: fixed !important;
    bottom: -0.3rem;
    right: -34%;

}

.cdr {
    border-radius: 40px !important;
    width: 295px;
    height: 40px;
    font-size: 18px !important;
}

.vehcrd {
    border-radius: 12px !important;
    background-color: #F8F8F8 !important;
    border: 1px solid #AEAEAE !important;
}
.vehcrd-icon {
    background: #FFFFFF;
    border: 1px solid #C5C5C5;
    border-radius: 4px;
    height: 50px;
}
.vehcrd-icon i {
    margin-top: 15px;
    margin-left: 7px;
    font-size: medium;
}

.card.vehcrd:hover {
    box-shadow: none;
}

@media only screen and (max-width: 992px) {
    .border-style {
        height: auto;
        background-color: #EAEAEA;
    }

    .carrier-style {
        height: auto;
        background-color: #EAEAEA;
    }

}

.right-head {
    padding-top: 50px;
    color: #f7941d;
    font-size: 35px;
}


.register-head {
    margin-top: 10px;
    color: #f7941d;
    font-size: 35px;
}

.list-items ul>li {
    font-size: 30px;
    color: #f7941d;
}

.list-items ul>li>h3 {
    color: black;
}


.logo-img {
    margin: 20px;
    width: 40%;
    height: 80px;
}

.logoImg {
    width: 20%;
    height: 80px;
    margin-bottom: 25px;
}

.invite-screen {
    padding: 50px;
}

.invite-list li {
    margin-left: 40px;
}

.esign {
    display: inline;
}

.esignStrong {
    display: inline;
    font-weight: bolder;
}

.mailto {
    font-size: 1em;
}

.eld-logo {
    margin: 5px;
    width: 100px;
    height: 100px;
}


:root {
    --card-line-height: 1.2em;
    --card-padding: 1em;
    --card-radius: 0.5em;
    --color-green: #558309;
    --color-gray: #e2ebf6;
    --color-dark-gray: #c4d1e1;
    --radio-border-width: 2px;
    --radio-size: 1.5em;
}

/* body {
  background-color: #f2f8ff;
  color: #263238;
  font-family: 'Noto Sans', sans-serif;
  margin: 0;
  padding: 2em 6vw;
} */

.grid {

    /* grid-gap: var(--card-padding); */
    /* margin: 0 auto;
max-width: 60em;
padding: 0; */

    @media (min-width: 42em) {
        grid-template-columns:

            repeat(3, 1fr);
    }

}

.card {
    background-color: #fff;
    border-radius: var(--card-radius);
    position: relative;
}

.card:hover {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
}

.radio {
    font-size: inherit;
    margin: 10px;
    position: absolute;
    right: auto;
    /* right: calc(var(--card-padding) + var(--radio-border-width)); */
    /* top: calc(var(--card-padding) + var(--radio-border-width)); */
}

@supports (-webkit-appearance: none) or (-moz-appearance: none) {
    .radio {
        -webkit-appearance: none;
        -moz-appearance: none;
        background: #fff;
        border: var(--radio-border-width) solid var(--color-gray);
        border-radius: 50%;
        cursor: pointer;
        height: var(--radio-size);
        outline: none;
        transition: background 0.2s ease-out,
            border-color 0.2s ease-out;
        width: var(--radio-size);
    }

    .radio::after {
        border: var(--radio-border-width) solid #fff;
        border-top: 0;
        border-left: 0;
        content: '';
        display: block;
        height: 0.75rem;
        left: 25%;
        position: absolute;
        top: 50%;
        transform: rotate(45deg) translate(-50%, -50%);
        width: 0.375rem;
    }

    .radio:checked {
        background: var(--color-green);
        border-color: var(--color-green);
    }


    .card:hover .radio {
        border-color: var(--color-dark-gray);


    }

    .card:checked {
        border-color: var(--color-green);
    }
}

.eld-scroll {
    width: 20px !important;
    overflow-x: auto;
}

.terms-scroll {
    overflow-y: auto;
    height: 50vh;
}

input.checkBox {
    width: 25px;
    height: 25px;
}

.plan-details {
    width: 150px;
    min-height: 150px;
    margin: 5px;
    border: var(--radio-border-width) solid var(--color-gray);
    border-radius: var(--card-radius);
    cursor: pointer;
    /* display: flex; */
    /* flex-direction: column; */
    padding: var(--card-padding);
    transition: border-color 0.2s ease-out;
}


.radio:checked~.plan-details {
    border-color: var(--color-green);
}

.radio:focus~.plan-details {
    box-shadow: 0 0 0 2px var(--color-dark-gray);
}

.radio:disabled~.plan-details {
    color: var(--color-dark-gray);
    cursor: default;
}

.radio:disabled~.plan-details .plan-type {
    color: var(--color-dark-gray);
}


.card:hover .radio:disabled {
    border-color: var(--color-gray);
}

.plan-type {
    color: var(--color-green);
    font-size: 1.5rem;
    font-weight: bold;
    /* line-height: 1em; */
}

.plan-cost {
    font-size: 2.5rem;
    font-weight: bold;
    /* padding: 0.5rem 0; */
}

.slash {
    font-weight: normal;
}

.plan-cycle {
    font-size: 2rem;
    font-variant: none;
    border-bottom: none;
    cursor: inherit;
    text-decoration: none;
}

.hidden-visually {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.iconcolor {
    color: #f7941d;
}

.border {
    border: solid 1px #dee2e6;
    padding: 5px;
}

.space {
    border: solid 1px #dee2e6;
    padding: 5px;
}

.groupseparator {
    display: flex;
    align-items: center;
}

.groupseparator .leftline {
    height: 2px;
    flex: 1;
    background-color: #dee2e6;

}

.groupseparator .rightline {
    height: 2px;
    background-color: #dee2e6;
    width: 5%;
}

.groupseparator h2 {
    padding: 0 8px;
    color: #f7941d;
    font-weight: bold;
    font-size: 14px;
}

.drop-image {
    width: 100%;
    height: 100%;
}

.img-divider {
    padding-right: 0px !important;
    border-right: 5px solid rgb(247, 148, 29);
}

.drop-vendor {
    color: rgb(247, 148, 29);
}

.hello-can {

    color: #f7941d;
    font-size: 25px;
    font-weight: 300;
    display: block;
}

.jKLQUD {
    display: block !important;
}

.cmny-name {
    font-size: 16px;
}

.cmny-no {
    margin-top: -10px;
}

.add-btn {
    background-color: rgb(105, 170, 79);
    border: rgb(105, 170, 79);
    ;
    color: #ffff;
}


.copy-btn {
    background-color: rgb(105, 170, 79);
    border: rgb(105, 170, 79);
    color: #ffff;
    width: max-content;
    margin-top: 25px;
    border-radius: 3px;
}

.edit-btn {
    background-color: rgb(8, 196, 243);
    border: rgb(8, 196, 243);
    color: #ffff;
    width: max-content;
    margin-top: 25px;
    border-radius: 3px;
}

.del-btn {
    background-color: rgb(241, 105, 50);
    border: rgb(241, 105, 50);
    color: #ffff;
    width: max-content;
    margin-top: 25px;
    border-radius: 3px;
}

.search-btn {
    background-color: #f7941d;
    border: #f7941d;
    color: #ffff;
    width: max-content;
    margin-top: 25px;
    border-radius: 3px;
}

/* ---------------INIT REGISTRATION---------------- */
.reg-logo {
    width: 25%;
    margin-left: 410px;
}

.regleft-logo {
    width: 50%;
}

.reg-title {
    text-align: center;
    margin-top: 40px;
}

.reg-title>b {
    font-size: 25px;
}

.section {
    cursor: pointer;
}

.shipper-bg {
    background-color: rgb(236, 235, 235);
    margin-bottom: 10px;
}

.iconship-style {
    font-size: 100px;
    margin-top: 50px;
    margin-left: 100px;
    color: #f1860b;
    margin-bottom: 50px;
}

.iconbrok-style {
    font-size: 100px;
    margin-top: 50px;
    margin-left: 100px;
    color: #000000;
    margin-bottom: 50px;
}

.iconcarr-style {
    font-size: 100px;
    margin-top: 50px;
    margin-left: 120px;
    color: #b91919;
    margin-bottom: 50px;
}

.reg-subtitle {
    text-align: center;
}

.reg-subtitle>b {
    font-size: 20px;
}

.reg-subtitle>p {
    font-size: 15px;
    margin-top: 5px;
}

.register-title {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    line-height: 37px;
    color: #000000;
}


.form-group.inputD {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
    padding-top: 10px;
    /* & + .input
  margin-top: 10px */
}

.form-group.input_label {
    color: #8597a3;
    position: absolute;
    top: 20px;
    transition: all 0.3s;
    /* @include transition(all 0.3s); */
}

.form-control.input_field {
    border: 0;
    padding: 0;
    z-index: 1;
    background-color: transparent;
    border-bottom: 1px solid #5E5A5A;
    font: inherit;
    font-size: 14px;
    line-height: 30px;
    border-radius: 0px;
}

.form-control.input_field:focus {
    outline: 0;
    border-bottom-color: 1px solid #5E5A5A;
    background-color: transparent;

}

.bdr {
    border-radius: 40px !important;
    width: 355px;
    height: 40px;
    font-size: 18px !important;
}

.form-control.cntno {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    top: 2px;
    background-color: transparent;
    border-bottom: 1px solid #5E5A5A !important;
}

.form-control.cntno:focus {
    outline: 0;
    border-bottom: 1px solid #5E5A5A !important;
    background-color: transparent;
}
.react-datepicker-popper[data-placement^="bottom"] {
    z-index: 5;
}

iframe {
    display: none !important;
    visibility: hidden !important;
  }

  .react-autosuggest__input {
    width: 100%;
    height: 29px;
    padding: 6px 6px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 11px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 34px;
    width: 303px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    overflow-y: auto;
    height: 40vh;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.expand-cell-header{
    cursor: pointer;
}

nav.nav.nav-tabs {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-top: 7px;
}

.nav-tabs .nav-link.active{
    font-weight: bold !important;
    color: #f7941d !important;
    text-transform: uppercase !important;
    border-color:none !important;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}


.nav-tabs .nav-link{
    font-weight: 500 !important;
    color: black !important;
    text-transform: uppercase !important;
    border-color:none !important;
}

/* a{
    color: black !important;
    text-transform: uppercase;
} */

.react-datepicker-popper{
    z-index: 2 !important;
}

.expand-cell{
    cursor: pointer !important;
}

.phone-number-select-width{
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

input::-ms-reveal {
    display: none;
}


